<template>
  <v-main class="test" style="background-color: #f5f5f7;color: #1c1b29">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <div class="widjet" id="a-prokat-vigjet" light>
      <v-col class="widjet_cols" dense>
        <v-row v-if="isMobile">
          <v-col
            v-if="cityFilter"
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
            style="margin-bottom: -25px"
          >
            <v-select
              color="black"
              background-color="white"
              dense
              :items="citiesList"
              item-text="name"
              item-value="id"
              v-model="filters.cityId"
              label="Город"
              required
              outlined
              @change="getCars"
            ></v-select>
          </v-col>
          <v-col
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
          >
            <v-menu
              ref="dateAtMenu"
              v-model="dateAtMenu"
              :close-on-content-click="true"
              :nudge-right="40"
              :return-value.sync="filters.dateAt"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  background-color="white"
                  dense
                  v-model="dateTextAt"
                  label="Начало аренды"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :disabled="!filters.cityId"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="ru-ru"
                :min="minMaxDate('dateAt')"
                :show-adjacent-months="true"
                first-day-of-week="1"
                no-title
                v-if="dateAtMenu"
                full-width
                @click:date="$refs.dateAtMenu.save(filters.dateAt)"
                v-model="filters.dateAt"
                @change="getCars"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-btn
          class="filter_btn"
          v-if="isMobile && this.filters.cityId && !loading"
          @click="showFilterClick"
        >
          <v-icon>mdi-format-list-bulleted</v-icon>Фильтры
        </v-btn>

        <v-dialog
          style="z-index: 500000"
          v-model="filters.visible"
          class="overflow-hidden"
          v-if="isMobile"
          persistent
          fullscreen
          hide-overlay
        >
          <v-card style="background-color: #f0f0f0;overflow: hidden">
            <v-toolbar
              style="background-color: #f0f0f0;margin-bottom: 10px;box-shadow: none;padding: 0 10px"
            >
              <h3>Фильтры</h3>
            </v-toolbar>
            <v-row>
              <v-col class="mx-6" style="padding:0 12px">
                <v-text-field
                  class="filter_input"
                  style="font-weight: 700"
                  color="black"
                  background-color="white"
                  dense
                  label="Цена до"
                  outlined
                  type="number"
                  v-model="filters.price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-6" style="padding:0 12px">
                <v-select
                  style="font-weight: 700"
                  class="filter_input"
                  color="black"
                  background-color="white"
                  dense
                  :items="transmissions"
                  v-model="filters.transmissions"
                  label="Коробка"
                  required
                  outlined
                  multiple
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-6" style="padding:0 12px">
                <v-select
                  style="font-weight: 700"
                  class="filter_input"
                  color="black"
                  background-color="white"
                  dense
                  outlined
                  multiple
                  v-model="filters.carClasses"
                  :items="carClasses"
                  label="Класс"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-6" style="padding:0 12px">
                <v-select
                  class="filter_input"
                  style="font-weight: 700"
                  color="black"
                  background-color="white"
                  dense
                  :items="fuels"
                  label="Топливо"
                  v-model="filters.fuels"
                  required
                  outlined
                  multiple
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mx-6" style="padding:0 12px">
                <v-select
                  style="font-weight: 700"
                  color="black"
                  class="filter_input"
                  background-color="white"
                  dense
                  :items="seatCount"
                  label="Число мест"
                  v-model="filters.seatCount"
                  required
                  outlined
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
          <v-btn
            bottom
            fixed
            class="filter_btn"
            style="top: 550px"
            @click="hideLayout"
          >
            Показать авто</v-btn
          >
        </v-dialog>
        <v-row
          v-if="!isMobile && cityFilter"
          :class="showFilterOnScreen ? 'showFilter' : 'hideFilter'"
          style="margin-bottom: -40px"
        >
          <v-col
            style="margin-left: -2px"
            class="filter_input col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          >
            <v-select
              color="black"
              background-color="white"
              dense
              :items="citiesList"
              item-text="name"
              item-value="id"
              v-model="filters.cityId"
              label="Город"
              required
              outlined
              @change="getCars"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="!isMobile">
          <v-col
            class="filter_input col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2"
          >
            <v-menu
              ref="dateAtMenu"
              v-model="dateAtMenu"
              :close-on-content-click="true"
              :nudge-right="40"
              :return-value.sync="filters.dateAt"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  background-color="white"
                  dense
                  v-model="dateTextAt"
                  label="Начало аренды"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :disabled="!filters.cityId"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="ru-ru"
                :min="minMaxDate('dateAt')"
                :show-adjacent-months="true"
                first-day-of-week="1"
                no-title
                v-if="dateAtMenu"
                full-width
                @click:date="$refs.dateAtMenu.save(filters.dateAt)"
                v-model="filters.dateAt"
                @change="getCars"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
          >
            <v-text-field
              color="black"
              background-color="white"
              :min="0"
              dense
              label="Цена до"
              outlined
              type="number"
              v-model="filters.price"
            ></v-text-field>
          </v-col>
          <v-col
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
          >
            <v-select
              color="black"
              background-color="white"
              dense
              :items="transmissions"
              v-model="filters.transmissions"
              label="Коробка"
              required
              outlined
              multiple
            ></v-select>
          </v-col>
          <v-col
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
          >
            <v-select
              color="black"
              background-color="white"
              dense
              :items="seatCount"
              label="Число мест"
              v-model="filters.seatCount"
              required
              outlined
              multiple
            ></v-select>
          </v-col>
          <v-col
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
          >
            <v-select
              color="black"
              background-color="white"
              dense
              :items="carClasses"
              label="Класс"
              v-model="filters.carClasses"
              required
              outlined
              multiple
            ></v-select>
          </v-col>

          <v-col
            class="filter_input col-xs-6"
            xs="6"
            sm="6"
            md="4"
            lg="2"
            xl="2"
          >
            <v-select
              color="black"
              background-color="white"
              dense
              :items="fuels"
              label="Топливо"
              v-model="filters.fuels"
              required
              outlined
              multiple
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-layout row wrap style="margin:auto;width: 100%">
        <v-flex
          v-if="!allCars.length && filters.cityId && !loading"
          style="margin: 0 40px"
        >
          <h2>
            {{ noCarsText }}
          </h2>
          <p>
            Попробуйте изменить настройки фильтра или посмотреть авто на другие
            даты
          </p>
        </v-flex>
        <v-flex class="car_card" v-for="car in allCars" :key="car.id">
          <cars-card-vidjet
            :querylink="querylink"
            :car="car"
            class="mb-3 center-webkit"
            style="margin-bottom: 40px"
          ></cars-card-vidjet>
        </v-flex>
      </v-layout>
      <v-row justify="center" overflow-hidden>
        <v-dialog class="overflow-hidden" persistent fullscreen hide-overlay>
          <v-card height="800"> </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" overflow-hidden>
        <v-dialog
          class="overflow-hidden test-test"
          persistent
          max-width="350"
          overlay-color="black"
          overlay-opacity="0.4"
        >
          <v-card class="d-flex align-center"> </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-main>
</template>
<script>
import moment from "moment";
import axios from "axios";
import carsCardVidjet from "@/components/vidjet/carsCard.vue";
import CarsCardVidjet from "@/components/vidjet/carsCard.vue";
export default {
  name: "Vidjet",
  components: { CarsCardVidjet },
  comments: {
    carsCardVidjet
  },
  data() {
    return {
      resultList: [],
      cityFilter: false,
      timer: 0,
      loading: false,
      dateAtMenu: false,
      dateToMenu: false,
      options: ["Авто", "Квартиру"],
      showCars: "Авто",
      noCarsText: "К сожалению, по выбранным фильтрам нет свободных авто :(",
      isMobile: false,
      isLarge: false,
      showFilterMobile: false,
      windowAtBottom: false,
      origin: window.origin,
      showPopUp: false,
      disableCar: false,
      disableInvest: false,
      citiesList: [],
      showFilterOnScreen: true,
      utm_channel: "site_widget",
      successPromoText:
        "Ваша заявка находится в обработке, в скором времени с Вами свяжется специалист.",
      showSwitcher: false,
      filters: {
        dateAt: moment().format("YYYY-MM-DD"),
        cityId: null,
        tranmissions: null,
        seatCount: null,
        models: null,
        carClasses: null,
        fuels: null,
        carAdres: null,
        price: null,
        visible: false
      },
      allCities: [],
      car: null,
      bookingState: "",
      allFilials: "",
      currentCityId: "",
      querylink: ""
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.get("cityId"));
    this.filters.cityId = urlParams.get("cityId") || this.filters.cityId;
    if (!this.filters.cityId) {
      this.cityFilter = true;
    }
  },
  mounted() {
    this.$vuetify.theme.dark = false;
    const html = document.getElementsByTagName("html")[0];
    html.style.overflow = "hidden";
    if (this.filters.cityId) {
      this.getCars();
    }
    this.getCities();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.onResize();
    const link = window.location.href.split("?");
    if (link?.length > 1) {
      this.querylink = link[1] + "&channel=" + this.utm_channel;
    } else {
      this.querylink = "channel=" + this.utm_channel;
    }
  },
  beforeMount() {
    const html = document.getElementsByTagName("html")[0];
    html.style.overflow = "";
    this.$vuetify.theme.dark = true;
  },
  computed: {
    transmissions() {
      return this.resultList
        ?.map(i => i.transmission)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    cities() {
      return this.allCities
        ?.map(i => i.transmission)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    testHref() {
      return window.location.href;
    },
    statuses() {
      return this.resultList
        ?.map(i => i.status)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    fuels() {
      return this.resultList
        ?.map(i => i.fuel)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    carAdres() {
      return this.resultList
        ?.map(i => i.carAdres.recordTitle)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    seatCount() {
      return this.resultList
        ?.map(i => i.seatCount)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    models() {
      return this.resultList
        ?.map(i => i.model)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    carClasses() {
      return this.resultList
        ?.map(i => i.carClass)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    dateTextAt() {
      return moment(this.filters.dateAt).format("DD.MM.YYYY");
    },
    allCars() {
      let filteredCars = this.resultList;
      if (this.filters?.price) {
        filteredCars = filteredCars.filter(o => {
          return o.minPrice <= this.filters.price;
        });
      }
      if (this.filters?.transmissions?.length) {
        filteredCars = filteredCars.filter(o => {
          return this.filters.transmissions.includes(o.transmission);
        });
      }
      if (this.filters?.models?.length) {
        filteredCars = filteredCars.filter(o => {
          return this.filters.models.includes(o.model);
        });
      }
      if (this.filters?.fuels?.length) {
        filteredCars = filteredCars.filter(o => {
          return this.filters.fuels.includes(o.fuel);
        });
      }
      if (this.filters?.carClasses?.length) {
        filteredCars = filteredCars.filter(o => {
          return this.filters.carClasses.includes(o.carClass);
        });
      }
      if (this.filters?.seatCount?.length) {
        filteredCars = filteredCars.filter(o => {
          return Math.min(...this.filters.seatCount) <= o.seatCount;
        });
      }
      if (this.filters.carAdres?.length) {
        filteredCars = filteredCars.filter(o => {
          return this.filters.carAdres.includes(o.carAdres.recordTitle);
        });
      }
      filteredCars.sort((a, b) => {
        if (a.status.id === b.status.id) {
          return b.price - a.price;
        }
        return a.status.id > b.status.id ? 1 : -1;
      });
      return filteredCars || [];
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getCars() {
      this.loading = true;
      axios
        .get(
          `https://app.a-prokat.com/freecars/cars?cityId=${
            this.filters.cityId
          }&date=${moment(this.filters.dateAt).format(
            "YYYY-MM-DD"
          )}&haveFilials=false`
        )
        .then(res => {
          this.resultList = res.data;
          this.loading = false;
          setTimeout(() => {
            window.parent.postMessage(
              {
                height: Math.max(
                  document.getElementById("a-prokat-vigjet").scrollHeight,
                  document.getElementById("a-prokat-vigjet").offsetHeight
                )
              },
              "*"
            );
            //   parent?.resizeIframe();
          }, 1500);
        });
    },
    getCities() {
      axios.get("https://app.a-prokat.com/freecars/cities").then(res => {
        this.citiesList = res.data;
      });
    },
    minMaxDate(type) {
      if (type === "dateAt") return moment().format("YYYY-MM-DD");
      if (type === "carProductYear") return "1980-01-01";
    },
    showFiltersPC() {},
    clearFilters() {},
    goToApartments() {},
    goToPartnersMoreInfo() {},
    showFilterClick() {
      this.filters.visible = true;
      document.hidden = true;
    },
    hideLayout() {
      this.filters.visible = false;
      document.hidden = false;
    },
    setDate(date) {
      this.filters.dateAt = date;
      this.getCars();
    }
  }
};
</script>
<style scoped>
html {
  overflow: hidden;
}
</style>

<style lang="scss">
.filter_btn {
  background-color: #ffd249 !important;
  border-radius: 7px;
  text-align: center;
  &:hover {
    background-color: #f1be22;
  }
}
.help-btn {
  display: none;
}

.theme--light.v-application {
  background-color: #f5f5f7 !important;
}
.widjet {
  width: 100%;
  margin: auto;
  background-color: #f5f5f7;
  max-width: 1160px;
  font-family: "Open Sans";
  padding-bottom: 80px;
}
.showFilter {
  justify-content: space-between;
}
.filter_input {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  width: 100%;
  font-weight: 500;
  .v-input__slot {
    border-radius: 7px;
  }
}
.showFilter .filter_input {
  font-weight: 700;
}
.korobka {
  position: absolute;
}
.carTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subTitle {
  padding-right: 0px;
}
.center-webkit {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.btn-sticky {
  position: fixed;
  bottom: 0.5rem;
  left: calc(50% - 55px);
  transition-property: bottom;
  transition-duration: 500ms;
}

.btn-sticky--bottom {
  bottom: 7rem;
}

.amountOfChosenFilters {
  z-index: 1;
  position: absolute;
  left: 8px;
  top: -3px;
  border-radius: 100%;
  border: 1px solid;
  width: 15px;
  height: 15px;
  line-height: 13.5px;
  background-color: #db1912;
}
.switcherMobile {
  display: flex;
  justify-content: center;
}

.switcherDesktop {
  display: block;
  margin: 0px;
}

.hideFilter {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 1s ease-out;
}

.showFilter {
  opacity: 1;
  margin: 40px -10px;
  margin-bottom: 0;
  margin-top: 0px;
  height: auto;
  transition: opacity 0.5s ease-in;
  overflow: auto;
}
.car_card {
  flex: 0 1 auto;
  width: 33.3333333%;
  justify-content: center;
}
.v-list {
  margin-top: 5px;
}
.widjet_cols {
  background-color: #f5f5f7;
  padding: 5px;
  display: flex;
  justify-content: center;
  margin: 10px auto 10px;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 0;
}
@media screen and (max-width: 1100px) {
  .car_card {
    width: 50%;
  }
  .showFilter {
    justify-content: space-around;

    margin-top: 80px;
    margin: 40px 20px;
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 800px) {
  .car_card {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .filter_input {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .widjet_cols {
    max-width: 370px;
  }
}
</style>
